import React from 'react'

import Layout from '../components/layout'
import { Contact } from '../components/Contact'

export const ContactPage = () => (
  <Layout seo={{ title: 'Contact Us' }}>
    <Contact />
  </Layout>
)

export default ContactPage
