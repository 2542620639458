import React from 'react'

import { INTERNAL_URLS, EXTERNAL_URLS } from '../../config/urls'
import { PHONE } from '../../config/business-info'
import Map, { mapboxgl, Marker } from '../common/Map'
import { PhoneLink } from '../common/Link/PhoneLink'

import landingStyles from '../Landing/Landing.module.scss'
import styles from './Contact.module.scss'

import ArrowRight from '../svg/ArrowRight'

export class Contact extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <div className={styles.page}>
          <div className={styles.pageContent}>
            <div className={landingStyles.aboutSectionBorder} />
            <h2 className={styles.headerTitle}>
              We're looking forward to hearing from you.
            </h2>
            <div className={styles.headerSubtitle}>
              <u>
                <PhoneLink phone={PHONE.OFFICE}>Call our office</PhoneLink>
              </u>{' '}
              to make an appointment and we'll do our best to care of your
              health and well-being.
            </div>
            <div className={styles.headerEmergencyNote}>
              For life-threatening emergencies, call "911" or proceed to the nearest emergency department.
            </div>

            <div className={styles.infoColumnInfo}>
              <div className={styles.infoSection}>
                <div className={styles.infoSectionRow}>
                  <p className={styles.infoSectionHeader}>Phone</p>
                  <p className={styles.infoSectionEntry}>
                    <span className={styles.entryLine}>
                      <PhoneLink
                        phone={PHONE.OFFICE}
                        className={styles.telLink}
                      >
                        {PHONE.OFFICE}
                      </PhoneLink>
                    </span>
                  </p>
                </div>
                <div className={styles.infoSectionRow}>
                  <p className={styles.infoSectionHeader}>Fax</p>
                  <span className={styles.entryLine}>
                    <PhoneLink phone={PHONE.FAX} className={styles.telLink}>
                      {PHONE.FAX}
                    </PhoneLink>
                  </span>
                </div>
              </div>

              <div className={styles.infoSection}>
                <p className={styles.infoSectionHeader}>Our Location</p>
                <p className={styles.infoSectionEntry}>
                  <span className={styles.entryLine}>Smyth Medical Plaza</span>
                  <span className={styles.entryLine}>
                    27871 Smyth Drive #102
                  </span>
                  <span className={styles.entryLine}>Valencia, CA 91355</span>
                </p>
                <div className={styles.infoSectionEntryLink}>
                  <a
                    href={EXTERNAL_URLS.GMAPS_DIRECTIONS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Get Directions
                    <ArrowRight className={styles.infoLinkArrow} />
                  </a>
                </div>
              </div>

              <div className={styles.infoSection}>
                <p className={styles.infoSectionHeader}>Our Hours</p>
                <p className={styles.infoSectionEntry}>
                  <span className={styles.entryLine}>
                    Monday through Friday
                  </span>
                  <span className={styles.entryLine}>9AM - 5PM</span>
                </p>
                <div className={styles.infoSectionEntryLink}>
                  Closed Holidays
                </div>
              </div>
            </div>
          </div>
        </div>

        <Map
          className={styles.mapContainer}
          options={{
            attributionControl: false,
            style: 'mapbox://styles/mattnish/ckl5oj6pg2db118o7k7k2v61h',
            minZoomNumber: 10,
            maxZoomNumber: 20,
            zoom: 15,
            center: [-118.567821, 34.439823],
            interactive: true,
          }}
          onLoad={map => {
            new Marker().setLngLat([-118.567821, 34.439823]).addTo(map)

            map.scrollZoom.disable()

            map.addControl(
              new mapboxgl.NavigationControl({ showCompass: false }),
              'top-right'
            )
          }}
        />
      </div>
    )
  }
}
