import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

/*
 * mapbox-gl-js >=v2 cannot be transpiled without additional Webpack configuration:
 * @see https://docs.mapbox.com/mapbox-gl-js/api/#transpiling-v2
 */
import mapboxgl, { Marker } from '!mapbox-gl'
import 'styles/mapbox.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import { isBrowser } from 'utils/environment'

// Export Mapbox to safely use mapboxgl
export { mapboxgl, Marker }

const MAPBOX_TOKEN =
  'pk.eyJ1IjoibWF0dG5pc2giLCJhIjoiY2tjZm1sdnlwMGpnaTJ6cGhsNDU3YWZ0ayJ9.7wE0reMCWZy-11AVScWJow'

const Map = ({ className, options, onLoad }) => {
  // this ref holds the map DOM node so that we can pass it into Mapbox GL
  const mapNode = useRef(null)

  // this ref holds the map object once we have instantiated it, so that we
  // can use it in other hooks
  const mapRef = useRef(null)

  // construct the map within useEffect is *similar* to componentDidMount if we
  // pass no items to the dependency array (2nd parameter). This allows us to
  // construct the map only once at the time the component is rendered.
  // TODO: We should fix the disabled exhaustive deps rule however as this error prone.
  useEffect(() => {
    // TODO: Move to dotenv secrets
    mapboxgl.accessToken = MAPBOX_TOKEN

    const map = new mapboxgl.Map({
      container: mapNode.current,
      ...options,
    })

    mapRef.current = map

    if (process.env.NODE_ENV === 'development') {
      window.map = map
    }

    map.on('load', () => {
      if (onLoad) {
        onLoad(mapRef.current, mapNode.current)
      }
    })

    // when this component is destroyed, remove the map
    return () => {
      map.remove()
      mapRef.current = null
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // Mapbox uses DOM bindings, so we bail out if we're in the server-side
  // rendering context rather than the browser
  if (!isBrowser) {
    return null
  }

  return <div className={className} ref={mapNode} />
}

Map.propTypes = {
  className: PropTypes.string,
  options: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
    bounds: PropTypes.arrayOf(PropTypes.number),
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    styles: PropTypes.arrayOf(PropTypes.string),
    padding: PropTypes.number,
    sources: PropTypes.object,
    layers: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  onLoad: PropTypes.func,
}

Map.defaultProps = {
  options: {},
}

export default Map
